@mixin aix-pill {
    @include fz(1.2);
    @include lh(1.4);
    text-wrap: nowrap;
    border-radius: 15px;
    border: 1px solid $color-quaternary-black;
    height: 24px;
    padding: 4px 8px;
    min-width: 32px;
    text-align: center;
    &--active {
        font-weight: 600;
    }
}

.aix-pill {
    @include aix-pill;

    &.dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 8px;
        background-color: $aix-color-secondary--light;
        height: auto;
    }

    &.multiple-text {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 8px;
        background-color: $aix-color-secondary--light;

        &.error {
            border-color: #DE5E56;
        }

        &.disabled {
            pointer-events: none;
        }

        &.last-pill {
            white-space: nowrap;
        }
    }

    &.tag {
        display: inline-block;
        margin: 0 4px 0 0;

        &.error {
            border-color: $color-red;

            i {
                color: $color-red;
            }
        }

        &.warn {
            border-color: $color-orange;

            i {
                color: $color-orange;
            }
        }

        &.alert {
            border-color: $color-yellow;

            i {
                color: $color-yellow;
            }
        }

        &.success {
            border-color: $color-green;

            i {
                color: $color-green;
            }
        }

        &.ok {
            border-color: $color-green;

            i {
                color: $color-green;
            }
        }

        &.aix {
            border-color: $aix-color-secondary;

            i {
                color: $aix-color-secondary;
            }
        }
    }

    &.note {
        background-color: $color-quinary-black;
    }

    &.error {
        background-color: lighten($color-red, 30%);
    }

    &.warn {
        background-color: lighten($color-orange, 30%);
    }

    &.alert {
        background-color: lighten($color-yellow, 20%);
    }

    &.success {
        background-color: lighten($color-green, 30%);
    }

    &.ok {
        background-color: lighten($color-green, 30%);
    }

    &.aix {
        background-color: $aix-color-secondary--light;
    }
}
