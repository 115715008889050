// /* ==========================================================================
//    Tooltip
//    ========================================================================== */
@import 'shared/variables';

// This is the actual tooltip
.aixTooltip__tooltip {
    @include drop-shadow;
    position: absolute;
    z-index: 9999;
    background: #fafafa;
    max-width: 550px;
    padding: 16px;
    text-align: left;

    @include fz(1.4);
    @include lh(2.4);
    color: $color-secondary-black;
    font-weight: 300;

    a {
        text-decoration: underline;
    }
}

[aixTooltip] {
    position: relative;
    margin-top: -4px;

    .tooltip__icon {
        @include fz(1.4);
        color: $aix-color-primary;
        margin-left: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .tooltip__text {
        @include fz(1.2);
        color: black;

        &:hover {
            cursor: pointer;
        }
    }
}
