@import 'libs/ui-components/src/lib/scss/shared/_variables';
@import 'libs/ui-components/src/lib/scss/shared/_mixins';

@import 'dynamic-forms/layout';
@import 'dynamic-forms/controls';
@import 'dynamic-forms/errors';

/** WARNING **/
.aix-expansion-header {
    &.aix-form__control--invalid.aix-form__control--invalid-required {
        border: 1px solid $aix-color-primary;
        z-index: z('default');
    }
}


.cancel-account-link {
    position: absolute;
    right: 0;
    padding-top: 40px;
    z-index: z('layer-0');
}
