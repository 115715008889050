@import 'shared/variables';
@import 'aix_brand';

$brand-color: map-get($brand-colors, 'primary');

.shimmer {
  font-family: "Lato";
  font-weight: 300;
  font-size: 3em;
  margin: 0 auto;
  padding: 0 140px 0 0;
  display: inline;
}
.shimmer::after {
  text-align: center;
  color: rgba(255,255,255,0.1);
  background-image: -webkit-linear-gradient(
    to right,
    rgba(map-get($brand-colors, 'primary'), 0),
    rgba(map-get($brand-colors, 'primary'), 0.1),
    rgba(map-get($brand-colors, 'primary'), 0.2),
    rgba(map-get($brand-colors, 'primary'), 0.25),
    rgba(map-get($brand-colors, 'primary'), 0.2),
    rgba(map-get($brand-colors, 'primary'), 0.1),
    rgba(map-get($brand-colors, 'primary'), 0)
  );
  background-image: -moz-linear-gradient(
    to right,
    rgba(map-get($brand-colors, 'primary'), 0),
    rgba(map-get($brand-colors, 'primary'), 0.1),
    rgba(map-get($brand-colors, 'primary'), 0.2),
    rgba(map-get($brand-colors, 'primary'), 0.25),
    rgba(map-get($brand-colors, 'primary'), 0.2),
    rgba(map-get($brand-colors, 'primary'), 0.1),
    rgba(map-get($brand-colors, 'primary'), 0)
  );
  background-image: linear-gradient(
    to right,
    rgba(map-get($brand-colors, 'primary'), 0),
    rgba(map-get($brand-colors, 'primary'), 0.1),
    rgba(map-get($brand-colors, 'primary'), 0.2),
    rgba(map-get($brand-colors, 'primary'), 0.25),
    rgba(map-get($brand-colors, 'primary'), 0.2),
    rgba(map-get($brand-colors, 'primary'), 0.1),
    rgba(map-get($brand-colors, 'primary'), 0)
  );
  -webkit-background-size: 120px 100%;
  -moz-background-size: 120px 100%;
  background-size: 300px 100%;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}


/* Placeholder */

.placeholder::after {
  background: $color-quinary-black;
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.placeholder.color {
  &-white::after {
    background: white !important;
  }
}

.loaded {
  -webkit-animation: fadeout 6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 6s; /* Firefox < 16 */
  -ms-animation: fadeout 6s; /* Internet Explorer */
  -o-animation: fadeout 6s; /* Opera < 12.1 */
  animation: fadeout 6s;
}
@-moz-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}