@mixin application-container {
    min-width: $width-min-container;
    max-width: $width-max-container;
    margin: -60px 40px 40px;
   
    // Breakpoints
    // 1024
    // 1280
    // 1600

    @media screen and (max-width: 1024px) {
        // Placeholder and example media query
    }
    @media screen and (min-width: 1025px) and (max-width: 1599px) {
        // Placeholder and example media query
    }
    @media screen and (min-width: 1600px) {
        margin: -60px auto 40px;
    }
}