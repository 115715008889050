/**
  * !! PLEASE DO NOT ADD TO THIS FILE
  **/

/**
 * This is the common section header CSS component.
 * It supports an h3 of header text and form controls.
 * See ui-components kitchen sink for usage and examples.
 **/

.header--section {
    @include flex-between-center;
    border-bottom: 2px dotted $color-quaternary-black;
    margin-bottom: 16px;
    min-height: 32px;
    padding: 0 0 8px 0;

    &.border-bottom-none {
        border-bottom: none;
    }

    div.-heading {
        display: flex;
        flex-basis: 100%; // Necessary for IE11; prevents button text cut off

        h3 {
            font-size: 20px;
            line-height: 24px;
            margin: 0px;
        }
    }

    div.-controls {
        // Prevents controls from wrapping to a new line
        display: flex;
        flex-basis: 100%; // Necessary for IE11; prevents button text cut off
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
}
