@charset "UTF-8";

// /* ==========================================================================
//    Variables
//    ========================================================================== */

//
// Custom Controls
// -------------------------

$height-form-control: 56px;
$height-form-control-textarea: 97px;
$margin-bottom-form-control: 16px;
$size-check-radio: 18px;
$size-check-radio-sm: 16px;
$size-radio-indicator: 10px;
$size-toggle-card: 300px;
$margin-left-toggle-card: 20px;
$transition-duration: 0.25s;
$checkbox-custom-padding-left: 28px;

//
// Main element dimensions
// -------------------------

$width-min-container: 990px;
$width-max-container: 1600px;
$width-min-body: 1024px;
$width-process-top-content-btns: 100%;
$width-process-mx-centered-container: 690px;
$width-process-sidebar: 280px;
$width-sidebar: 224px;
$width-max-form: 800px;

$height-viewport: 100vh;
$height-main-utility: 64px;
$height-main-utility-collapsed: 40px;
$height-main-nav: 230px;
$height-main-header: 80px;
$height-process-subheader: 50px;
$height-process-header: 80px;
$height-process-sidebar-footer-plus: 180px;
$height-process-footer: 91px;

//
// Typography
// -------------------------

$ff-primary: 'AktivGrotesk', sans-serif;
$ff-secondary: 'AktivGrotesk', sans-serif;

//
// Colors
// --------------------------------

/** Branding Color Variables **/
/*
    You can find the default AIX branding variables defined in _aix_brand.scss

    These variables below are defined as alert-level colors to help aid developers
    to apply the correct color classes to their elements.

    See _aix_brand.scss for color classes.
*/

/** Canary Signal Branding Color Palette **/
/**
    These colors will show when an element is not properly treated
    for branding through classnames. This is a developer helper and a
    signal that work needs to happen to ensure whitelabeling will
    work on an element

    The SASS does not have access to the branded colors! 
    
        map-get($brand-colors, 'primary'); // WILL NOT WORK
    
    To access branding colors, you _must_ reference either a generated class 
    from the brands.css file or the Javascript reference.
**/

$color-primary: red !default;
$color-primary--dark: darken($color-primary, 20) !default;
$color-primary--light: lighten($color-primary, 20) !default;

$color-secondary: blue !default;
$color-secondary--dark: darken($color-secondary, 20) !default;
$color-secondary--light: lighten($color-secondary, 20) !default;

$color-navbarbg: #232B2E !default;
$color-navbartext: #FFFFFF !default;
$color-navbarhover: #26a69a !default;

$brand-colors: (
    primary: $color-primary,
    primary--dark: $color-primary--dark,
    primary--light: $color-primary--light,

    secondary: $color-secondary,
    secondary--dark: $color-secondary--dark,
    secondary--light: $color-secondary--light,

    navbar--bg: $color-navbarbg,
    navbar--text: $color-navbartext,
    navbar--hover: $color-navbarhover
);


/** Application Color Palette **/

/*
    Default AIX branding
    • Primary Teal: 26A69A
    • Secondary Teal: 59D9CD
    • Secondary Teal (Lightened): EAFAF9
    • Tertiary Teal: 186860
    - From Alex McGonigle as of 01-29-2019
*/

$aix-color-primary: #26a69a;
$aix-color-primary--dark: #186860;
$aix-color-primary--light: #41d3bd;

$aix-color-secondary: #59d9cd;
$aix-color-secondary--dark: #186860 ;
$aix-color-secondary--light: #eafaf9;

$aix-color-navbarbg: #232B2E;
$aix-color-navbartext: #FFFFFF;
$aix-color-navbarhover: #26a69a;

/*
    System Blacks:
    • Primary Black: 263238
    • Secondary Black: 232B2E
    • Tertiary Black: 757575
    • Tertiary Black (Lightened): A8A8A8
    • Quaternary Black: E2E2E2
    • Quinary Black: F5F5F5
    - From Alex McGonigle as of 01-29-2019
*/
$color-primary-black: #263238;
$color-secondary-black: #232B2E;
$color-tertiary-black: #757575;
$color-tertiary-black--light: #A8A8A8;
$color-quaternary-black: #E2E2E2;
$color-quinary-black: #f5f5f5;

$color-white: #FFFFFF;
$color-black: #000000;

/*
    Notifications:
    • Green: 81C784
    • Yellow: FFF176
    • Orange: FFB74D
    • Red: EF5350
    - From Alex McGonigle as of 01-29-2019
*/
$color-red: #EF5350;
$color-error-red: $color-red;
$color-green: #81C784;
$color-success-green: $color-green;
$color-orange: #FFB74D;
$color-warning-orange: $color-orange;
$color-yellow: #FFF176;

$z-layers: (
  'bottom-default': -1,
  'default': 1,
  'layer-0': 2,
  'layer-1': 5,
  'layer-2': 10,
  'layer-3': 15,
  'layer-3.1': 20,
  'layer-4': 25,
  'layer-5': 30,
  'layer-6': 35,
  'sticky': 100,
  'sticky-bottom': 98,
  'dropdown-list': 99,
  'modal': 5000,
  'dropdown': 4000
);
