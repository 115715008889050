@supports (-webkit-hyphens:none) {
    .aix-flex-grid__colx {
        -webkit-flex: 1;
        margin-right: -0.5px;
    }
}

@supports not (-webkit-hyphens:none) {
    .aix-flex-grid__colx {
        flex-direction: column;
        position: relative;
        flex: 1 1 auto;
    }
}

.aix-flex-gridx {
    display: flex;
    flex-wrap: wrap;

    &__col {
        // Firefox and IE11 fix for flex-basis, apply when needed
        // Bug: https://bugzilla.mozilla.org/show_bug.cgi?id=876985

        border: 1px solid red;
        &--no-basis {
            flex: 1 1;
        }

        &--bordered {
            border: 1px solid $color-quaternary-black;
            width: 100%;
        }

        &--1of1,
        &--full {
            flex: 1 1 auto;
        }

        &--1of2 {
            flex: 1 1 50%;

            // We have to set a max-width when we have flex-basis inside a flex container
            // Bug: https://github.com/philipwalton/flexbugs/issues/223
            max-width: calc(50% + 1px);

            &--5 {
                flex: 1 1 55%;
            }
        }

        &--1of3 {
            flex: 1 1 33.3333%;
            max-width: calc(33.3333% + 1px);
        }

        &--2of3 {
            flex: 1 1 66.6666%;
            max-width: calc(66.6666% + 1px);
        }

        &--1of4 {
            flex: 1 1 25%;
            max-width: calc(25% + 1px);
        }

        &--3of4 {
            flex: 1 1 75%;
            max-width: calc(75% + 1px);
        }

        &--1of5 {
            flex: 1 1 20%;
            max-width: calc(20% + 1px);
        }

        &--2of5 {
            flex: 1 1 40%;
            max-width: calc(40% + 1px);

            &.performance-history-grid-col {
                margin-right: 5%;
            }
        }

        &--3of5 {
            flex: 1 1 60%;
            max-width: calc(60% + 1px);
        }

        &--4of5 {
            flex: 1 1 80%;
            max-width: calc(80% + 1px);
        }
    }
    &__clearfix {
        flex: 1 1 100%;
        height: 0;
    }
}
