@import 'shared/variables';
@import 'shared/mixins';

@keyframes nav-collapse {
  from {
    height: $height-main-utility;
  }
  to {
    height: $height-main-utility-collapsed;
  }
}

@keyframes nav-expand {
  from {
    height: $height-main-utility-collapsed;
  }
  to {
    height: $height-main-utility;
  }
}

@mixin animation-nav-collapse {
  animation: nav-collapse .2s linear forwards;
}

@mixin animation-nav-expand {
  animation: nav-expand .1s linear forwards;
}

@keyframes logo-collapse {
  from {
    max-height: $height-main-utility;
  }
  to {
    max-height: $height-main-utility-collapsed;
  }
}

@keyframes logo-expand {
  from {
    max-height: $height-main-utility-collapsed;
  }
  to {
    max-height: $height-main-utility;
  }
}

@mixin animation-logo-collapse {
  animation: logo-collapse .2s linear forwards;
}

@mixin animation-logo-expand {
  animation: logo-expand .1s linear forwards;
}

.nav-alert-container {
  top: 0;
  position: fixed;
  display: block;
  z-index: 9999;
  width: 100%;
}

.navbar {
    @include drop-shadow;
    @include font-weight-medium;

    width: 100%;
    min-width: 990px;
    padding: 0 24px;


    a {
      text-decoration: none;
    }

    .navbar__logo {
      padding: 8px 0;
      display: inherit;
      max-width: 320px;
      height: auto !important;
    }
}

.navbar.-expanded {
    @include animation-nav-expand;
    @include fz(1.6);
    @include lh(2.4);

    .navbar__logo {
        @include animation-logo-expand;
        max-height: $height-main-utility;
    }

    ul.navbar-items {
        padding: 20px 0 12px 0;
    }

    ul.-dropdown {
       @include h5-style;
    }
}

.navbar.-collapsed {
    @include animation-nav-collapse;
    @include fz(1.4);
    @include lh(2.4);

    .navbar__logo {
        @include animation-logo-collapse;
        max-height: $height-main-utility-collapsed;
    }

    ul.navbar-items {
      padding: 8px 0;
    }

    ul.-dropdown {
       @include h6-style;
    }
}

// Main navigation links
.navbar ul {
    li {
        display: inline-block;
        position: relative;
        list-style-type: none;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
    }
}

// Main navigation dropdown menus
@keyframes dropdown-expand {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

@keyframes dropdown-collapse {
    0% {
        transform: scaleY(1)
    }
    100% {
        transform: scaleY(0)
    }
}
.navbar ul.-dropdown {
    box-shadow: inset 0px 11px 8px -10px  rgba(0,0,0,0.16);
    color: $color-white;
    position: absolute;
    left:auto;
    right:0;
    top: 42px;
    z-index: 100;

    display: none;

    li {
      display: block;
      opacity: 1;
      margin-right: auto;
      padding: 4px 16px;
      min-width: 80px;
      &:first-child {
        padding: 16px 16px 0 16px;
      }
      &:last-child {
        padding: 0 16px 16px 16px;
      }

    }

    &.-closed {
      display: block;
      animation: dropdown-collapse 300ms ease-in-out forwards;
      transform-origin: top center;
    }

    &.-open {
      display: block;
      animation: dropdown-expand 300ms ease-in-out forwards;
      transform-origin: top center;
    }
}

.navbar.-collapsed ul.-dropdown {
  top: 31px; //$height-main-utility-collapsed;
}

// Masthead is the big block that holds the page title
.masthead {
    height: $height-main-nav;
    background-color: $color-primary-black;
}

.branding-notification {
  font-size: 14px;
}
