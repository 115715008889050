@import '../../shared/variables';

#app {
    position: relative;
    flex: 1 0 auto;

    &.register {
        background-color: $color-white;
    }
}

main {
    @include application-container;

    background: $color-white;
    display: block; // Required for IE11

    &.-no-background {
        background: transparent;
    }
}

section {
    margin-top: 16px;
    padding: 0 24px 24px 24px;

    // .-no-padding should rarely be used
    &.-no-padding {
        padding: 0;
    }

    // TODO: Clean up/minimize ad hoc changes
    &.-no-margin {
        margin: 0;
    }

    .section-item {
        padding-bottom: 24px;
        &:not(:last-child) {
            border-bottom: 1px solid $color-quaternary-black;
            margin-bottom: 24px;
        }
    }
}

@media print {
    #app {
        margin-top: 0;
    }
}

// /* ==========================================================================
//    Containers and Layouts
//    ========================================================================== */

.container {
    min-width: $width-min-container;
    max-width: $width-max-container;
    padding: 0;
    margin: 0 auto;
    justify-content: center;
}

/* layout-full-h only used by submit-confirmation; replace when possible - LMG 2018-07-17 */
.layout-full-h {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
