@import '../shared/variables';
@import '../shared/mixins';

// Custom checkboxes and radios
///////////////////////////////////////

.checkbox-custom,
.radio-custom {
    position: relative;
    display: inline-flex;

    min-height: $size-check-radio;

    margin-right: $checkbox-custom-padding-left;

    @include fz(1.4);
    @include lh(2);
    font-weight: 400;
    color: $color-primary-black;

    cursor: pointer;

    &__label {
        display: inline-block;
        vertical-align: top;
        min-height: $size-check-radio;
    }

    &__sublabel {
        display: block;

        @include fz(1.2);
        color: $color-tertiary-black;
        font-weight: 500;
        margin-top: 4px;
    }

    ~ .form-help-block {
        margin-top: 4px;
        padding-left: $checkbox-custom-padding-left;
    }

    &--indented {
        margin-left: 32px;
    }
}

.checkbox-custom input,
.radio-custom input {
    position: absolute;
    opacity: 0;
}

.radio-custom {
    &__label {
        font-weight: normal;
    }

    &-title {
        color: #232b2e;
        margin-bottom: -4px;
    }

    &-label-size {
        width: auto;
    }

    &-linear {
        display: flex;
        flex-direction: row;

        .radio-custom-wrapper-full {
            width: auto;
            margin-top: 0;
            margin-right: 20px;
        }
    }

    &-column {
        width: 100%;
        padding-bottom: 24px;

        &__1of3 {
            width: calc(33% - 12px);
        }

        &__1of2 {
            width: calc(50% - 24px);
        }
    }

    &-container {
        margin-bottom: calc($margin-bottom-form-control / 2);
        padding-bottom: calc($margin-bottom-form-control / 2);
        padding-left: 4px;
        margin-left: -4px;
        border: 1px solid transparent; // for pulse-unbordered-field animation
    }

    /** WARNING **/
    &-expansion-panel-content {
        display: flex;
        flex-wrap: wrap;
        padding: 24px;
        background: $color-quinary-black;
        justify-content: space-between;
    }

    &-expansion-panel-content-section {
        display: flex;
        justify-content: space-between;
    }
}

.checkbox-custom__indicator,
.radio-custom__indicator {
    display: block;
    width: $size-check-radio;
    height: $size-check-radio;
    flex-shrink: 0;

    margin-right: $checkbox-custom-padding-left - $size-check-radio - 1;

    border: 1px solid $color-quaternary-black;
    line-height: $size-check-radio;
    background-color: $color-white;
    user-select: none;

    &:before {
        content: '';
        display: block;
    }

    &.aix-form__control--invalid {
        width: $size-check-radio;
        height: $size-check-radio;
        line-height: $size-check-radio;
        outline-color: $color-red;

        @include hover {
            color: $color-red;
            outline-color: $color-red;
        }

        &.aix-form__control--invalid-required {
            @include form-field-required-style;
            border: none;

            @include hover {
                color: $aix-color-primary;
            }

            &.aix-form__control--dirty {
                outline-color: $color-red;
            }
        }
    }
}

.checkbox-custom__indicator {
    border-radius: 2px;

    &:before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f00c';
        @include fz(1.4);
        width: 0%;
        height: 100%;
        margin-left: 1.5px;
        padding: 2px 0;
        overflow: hidden;
        line-height: 1;
        transition: width 250ms;
    }

    &-sm {
        &:before {
            @include fz(1.2);
        }
    }
}

.radio-custom__indicator {
    border-radius: 50%;
    border: 1px solid $color-tertiary-black--light;
    transition: background-color 250ms;

    &:before {
        width: 100%;
        height: 100%;
        background-color: $color-white;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 150ms ease-out;
    }
}

// /* Hover */
.checkbox-custom:hover {
    .checkbox-custom__indicator {
        border-color: $aix-color-primary;
    }
}

.radio-custom:hover {
    .radio-custom__indicator {
        border-color: $aix-color-primary;
    }
}

// /* Checked state */
.checkbox-custom input:checked,
.checkbox-custom.checked,
.checkbox-custom.indeterminate {
    ~ .checkbox-custom__label {
        color: $color-black;
    }

    ~ .checkbox-custom__indicator {
        background-color: $aix-color-primary;
        border-color: $aix-color-primary;

        &:before {
            color: $color-white;
            width: 100%;
        }
    }

    .checkbox-custom {
        &__label {
            color: $color-black;
        }

        &__indicator {
            background-color: $aix-color-primary;
            border-color: $aix-color-primary;

            &:before {
                color: $color-white;
                width: 100%;
                margin-top: 1px;
            }
        }
    }
}

.radio-custom input:checked {
    ~ .radio-custom__label {
        color: $color-black;
    }

    ~ .radio-custom__indicator {
        border-color: $aix-color-primary;

        &:before {
            transform: scale(0.7);
            background-color: $aix-color-primary;
        }
    }
}

// /* Disabled state */
.checkbox-custom input.aix-disabled,
.checkbox-custom input:disabled,
.checkbox-custom.disabled {
    ~ .checkbox-custom__label {
        color: $color-tertiary-black--light;
        cursor: not-allowed;
        pointer-events: none;

        .checkbox-custom__sublabel {
            color: $color-quaternary-black;
        }
    }

    ~ .checkbox-custom__indicator {
        border-color: rgba($color-quaternary-black, 0.5);
        cursor: not-allowed;
        pointer-events: none;
    }

    .checkbox-custom {
        &__label {
            color: $color-tertiary-black--light;
            cursor: not-allowed;
            pointer-events: none;

            .checkbox-custom__sublabel {
                color: $color-quaternary-black;
            }
        }

        &__indicator {
            border-color: rgba($color-quaternary-black, 0.5);
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}

.radio-custom input.aix-disabled,
.radio-custom input:disabled {
    ~ .radio-custom__label {
        color: $color-tertiary-black--light;
        cursor: not-allowed;
        pointer-events: none;

        .checkbox-custom__sublabel {
            color: $color-quaternary-black;
        }
    }

    ~ .radio-custom__indicator {
        border-color: rgba($color-quaternary-black, 0.5);
        cursor: not-allowed;
        pointer-events: none;

        &.aix-form__control--disabled-required {
            outline: solid 1px $color-tertiary-black--light;
        }
    }
}

// /* Indeterminate State */
.checkbox-custom.indeterminate {
    .checkbox-custom__indicator {
        &:before {
            content: '\e814';
            width: 100%;
        }
    }
}

// /* Checked and disabled */
.checkbox-custom input:disabled:checked,
.checkbox-custom input.aix-disabled:checked,
.checkbox-custom.checked.disabled,
.checkbox-custom.indeterminate.disabled {
    ~ .checkbox-custom__indicator {
        background-color: $color-tertiary-black--light;
        border-color: $color-tertiary-black--light;
    }

    .checkbox-custom__indicator {
        background-color: $color-tertiary-black--light;
        border-color: $color-tertiary-black--light;
    }
}

.radio-custom input:disabled:checked,
.radio-custom input.aix-disabled:checked {
    ~ .radio-custom__indicator {
        border-color: $color-tertiary-black--light;

        &::before {
            background-color: $color-tertiary-black--light;
        }
    }
}

// /* Active */
@mixin checkbox-active {
    &:active &__indicator,
    &:active.checked &__indicator,
    &:active.indeterminate &__indicator {
        background-color: rgba($aix-color-primary, 0.25);
        transition: none;
    }
}

.checkbox-custom {
    @include checkbox-active;

    &:active input:checked ~ &__indicator,
    &:active.checked &__indicator,
    &:active.indeterminate &__indicator {
        background-color: rgba($aix-color-primary, 0.75);
    }
}

.radio-custom {
    @include checkbox-active;
}

// /* Misc */
.checkbox-custom--space-top {
    margin-top: 28px;
}

.checkbox-custom-wrapper {
    margin-top: 8px;
}

.checkbox-custom-wrapper-full {
    width: 100%;
    margin-top: 16px;

    &:first-child {
        margin-top: 0;
        padding-top: 0;
    }

    .checkbox-custom {
        margin-right: 0;
    }
}

// Custom classes for radio groups found in pre-signature preview and signing method
.radio-order-process {
    .radio-custom-label-size.aix-disabled {
        color: gray;

        .radio-custom__sublabel {
            color: gray;
        }
    }

    .radio-custom__label {
        font-weight: 500;
        color: black;
    }

    .radio-custom__sublabel {
        @include fz(1.4);
        display: block;
        margin-top: 8px;
        font-weight: 400;
        color: black;
    }
}

.radio-custom-wrapper-full {
    margin-top: 8px;

    .radio-custom {
        margin-right: 0;
    }
}

.checkbox-custom-label-size,
.radio-custom-label-size {
    width: auto;
}

.checkbox-custom-label-size.aix-disabled,
.radio-custom-label-size.aix-disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.radio-custom--space-top {
    margin-top: 28px;
}

// SSN and EIN decoration
.field-decoration {
    pointer-events: none;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    right: 16px;
    top: 20px;
    z-index: 100;
}

.standalone-dropdown {
    margin-left: -1px;
    margin-top: -1px;
    margin-right: 1px;
}
