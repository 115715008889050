.grid-container,
.content-container {
    margin-bottom: 24px;
    padding-bottom: 16px;
    min-width: 720px;
    width: 100%;

    &__full {
        min-width: 100%;
    }
}


.sidebar--left + .grid-container,
.sidebar--left + .content-container,
.sidebar--left + .aix-sticky__spacer + .content-container {
    border-left: 1px solid $color-quaternary-black;
    margin-left: -1px;
    padding: 24px 0px 24px 24px;
    margin-bottom: 0px;
}

// Supports making the sidebar _sticky_ work in IE11; otherwise our
// content-container expands to full width when `position:fixed`
// is applied (IE11 only) - LMG 2021-04-15
.sidebar--left.aix-sticky--stuck + .aix-sticky__spacer + .content-container,
.sidebar--left.aix-sticky--stuck + .aix-sticky__spacer + .grid-container {
    margin-left: $width-sidebar;
}
