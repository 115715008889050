.breadcrumbs {
    @include h5-style;
    max-width: 700px;

    li {
        margin-right: 8px;
        display: inline;

        &::after {
            content: '\f105';
            font-family: 'Font Awesome 5 Pro';
            margin-left: 8px;
            vertical-align: middle;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}
