// /* ==========================================================================
//    Base elements
//    ========================================================================== */

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 62.5%;
    zoom: 1;
}

body {    
    height: 100%;
    background-color: $color-quaternary-black;
    position: relative;

    min-width: $width-min-body;

    font-family: $ff-primary;
    @include lh(1.6);
    @include fz(1.4);

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}

textarea {
    font-family: $ff-primary;
    @include lh(1.6);
    @include fz(1.4);
}

a {
    cursor: pointer;
    text-decoration: none;
    transition-property: color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}

a:hover {
    text-decoration: underline;
}

b, strong {
    @include font-weight-medium;
}

hr {
    border-color: $color-quaternary-black;
    border-style: solid;
    clear: both;
    border-top: 0;
    margin-top: 32px;
}

@media print {
    @-moz-document url-prefix() {
        body {
            display: block;
        }
    }
}
