/**
  * !! PLEASE DO NOT ADD TO THIS FILE
  **/

/**
 * This is the common page header CSS component.
 * It supports an h2 of header text and form controls.
 * See ui-components kitchen sink for usage and examples.
 **/

.header--page {
    @include flex-between-top;
    border-bottom: 1px solid $color-quaternary-black;
    padding: 24px;
    align-items: center;

    &.-no-padding {
        padding: 0;
    }

    &.-no-border {
        border-bottom: none;
    }

    div.-heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 0px;
        }

        h5 {
            color: $color-tertiary-black;
            padding: 8px 0 0;
        }

        .-subtext {
            padding: 8px 0 0;
        }
    }

    div.-controls {
        // Prevents controls from wrapping to a new line
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;

        > * {
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    div.-extra {
        margin-right: 12px;
    }

    div.-footer {
        display: block;
    }
}

// Hack to prevent extra left-side indentation when using the pageHeaderField
// form component
.aix-form .header--page {
    padding: 24px 0;
}
