@import './shared';

.doc-preview {
    width: 180px;

    &__thumbnail {
        width: 180px;
        border: 1px solid $color-quaternary-black;
        position: relative;
        height: 233px;

        &:hover .doc-preview__action {
            visibility: visible;
        }

        &:hover .doc-preview__hover-outline {
            visibility: visible;
        }

        &:hover .doc-preview__action-button {
            display: inline;
        }

        &-selected {
            border: 2px solid $aix-color-primary;

            .doc-preview__thumbnail-image {
                width: 176px;
            }
        }
    }

    &__thumbnail-image {
        width: 178px;
        height: 100%;
    }

    &__unavailable,
    &__action {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__unavailable {
        font-weight: 600;
    }

    &__action {
        background-color: rgba(255, 255, 255, 0.5);
        visibility: hidden;

        &-button {
            display: none;
        }
    }

    &__hover {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;

        &-outline {
            border: 2px solid $aix-color-primary;
            cursor: pointer;
            height: 100%;
            width: 100%;
        }
    }

    &--actionbar {
        width: 180px;
    }

    &__title-container {
        display: flex;
        margin-top: 8px;
    }

    &__title {
        flex-grow: 1;
        @include h6-style;
        @include font-weight-medium;
    }

    &__title-tooltip {
        padding-top: 8px;
    }

    &__description {
        @include body-style-normal;
        color: $color-secondary-black;
    }

    &__date {
        @include body-style-normal;
        color: $color-tertiary-black;
    }
}
