.sidebar--left {
    border-right: 1px solid $color-quaternary-black;
    flex-basis: $width-sidebar;
    min-width: $width-sidebar;
    max-width: $width-sidebar;
    position: sticky;
    top: 0;
    z-index: z('sticky');
    padding: 24px 12px 24px 0;

    h4 {
        font-size: 16px;
        line-height: 24px;
    }

    section {
        padding: 0px;
        margin-top: 0px;
    }
}
