/**
 * This is the common app header CSS component.
 * It supports an h1 of header text,
 * subtext/breadcrumbs, and right-side controls.
 * See ui-components kitchen sink for usage and examples.
 **/

header.header--app {
    color: $color-white;
    margin: 0 auto 0;
    min-height: 110px;
    padding-top: 110px;
    padding-bottom: 80px;

    div.-container {
        @include flex-center-top;
        margin: 0 auto;
        min-width: $width-min-container;
        max-width: $width-max-container;        
    }

    div.-heading {
        display: flex;
        flex-basis: 70%; // Necessary for IE11; prevents button text cut off
        flex-direction: column;

        flex: 1; // Necessary for ellipsis mixin to work on flex child
        min-width: 0; // Necessary for ellipsis mixin to work on flex child

        margin: 0px 40px;
        @media screen and (min-width: 1600px) {
            margin: 0;
        }    

        h1 {
            @include ellipsis;
            font-size: 34px;
            line-height: 40px;
 

        }

        div.-subtext {
            // Can include breadcrumbs (see _breadcrumbs.scss)
        }

    }

    div.-controls {
        // Prevents controls from wrapping to a new line
        display: flex;
        flex-wrap: nowrap;
        margin-top: auto;

         > * {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
