ul.filter-list {
    padding-top: 8px;

    &.-no-padding {
        padding-top: 0;
    }

    &__aside {
        width: 210px;

        &:first-child {
            padding-top: 0;
        }
    }

    li {
        @include flex-between-top;
        @include fz(1.4);
        @include lh(2.4);
        margin-bottom: 8px;
        cursor: pointer;
        display: flex;

        .aix-pill {
            width: auto;
        }

        .filter-list-label {
            flex: 1;
        }
    }

    .group-divider {
        border-bottom: 1px solid $color-quaternary-black;
        padding-bottom: 16px;
    }
}
