//
// Behavior modifiers classes
/////////////////////////////////////

[hidden], .aix-hidden {
    display: none !important;
}

//
// Global classes
/////////////////////////////////////
.aix-text {
	margin: 0;
	@include fz(1.1);
	@include lh(2);
	color: $color-primary-black;

	a {
		@include hover {
			text-decoration: underline;
		}
	}

	&--sm {
		@include fz(1);
	}
	&--lg {
		@include fz(1.2);
	}
	&--xl {
		@include fz(1.3);
	}

	&--narrow {
		max-width: 610px;
	}
}
