@import '../shared/mixins';

@mixin button-hover {
    @include drop-shadow-hover;
    border-radius: 2px;
}

/* =====
    aix-btn base class
===== */
.aix-btn {
    display: inline-block;
    position: relative;

    max-width: 100%;

    padding: 12px 16px;
    border: 1px solid $color-tertiary-black--light;
    border-radius: 2px;

    text-align: center;
    text-decoration: none;
    font-family: $ff-primary;
    @include fz(1.4);

    font-weight: 600;
    color: $color-primary-black;

    background-color: transparent;

    cursor: pointer;
    outline: none;
    vertical-align: top;
    user-select: none;
    zoom: 1;
    white-space: nowrap;

    transition: color 0.25s, border-color 0.25s, background-color 0.25s, transform 0.25s;

    @include hover {
        @include button-hover;
    }

    &[disabled],
    &-inactive {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }

    + .aix-btn {
        margin-left: 12px;
    }

    // /* Firefox: Get rid of the inner focus border */
    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    // Modifiers
    &--primary {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }

    &--no-bg, // NB> no-bg modifier should be removed
    &--secondary {
        border-color: $color-primary;
        color: $color-primary;
        background-color: transparent;
    }

    &--link,
    &--tertiary {
        padding: 8px 0;
        border: 0 none;
        text-transform: none;
        @include fz(1.4);
        font-weight: 400;
        background-color: transparent;

        @include hover {
            box-shadow: none;
            border-radius: 0;
            text-decoration: underline;
        }
    }

    // Spacing rules for icons used inside buttons
    span.fa-regular {
        margin-right: 5px;

        &:before {
            margin-left: 0;
        }
    }
}

// TODO: Jorge talk with Linus for change this to u-p0 and fix CSS cascade issue
.no-padding {
    padding: 4px;

    button {
        width: 100%;
        text-decoration: none !important;
    }
}
