/* ==========================================================================
   Body text
   ========================================================================== */

p,
.-text--normal {
    @include body-style-normal;
}

.-text--strong {
    @include font-weight-medium;
}

small,
.-text--small {
    @include body-style-small;
}

.-text--large {
    @include body-style-large;
}

.-text--info {
    @include body-style-small;
    color: $color-tertiary-black;
}

.-text--label {
    @include text-style-label;
}

/* ==========================================================================
   Headers and headings
   ========================================================================== */

h1 {
    @include h1-style;
}

h2 {
    @include h2-style;
}

h3 {
    @include h3-style;
}

h4 {
    @include h4-style;
}

h5 {
    @include h5-style;
}

h6 {
    @include h6-style;
    color: $color-tertiary-black;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    margin: 0 0 8px 0;
    padding: 0;
}
