// /* ==========================================================================
//    Utility classes
//    ========================================================================== */

/* Clear inner floats*/
.u-clearfix {
    @include clearfix;
}

/* Relative positioning */
.u-relative {
    position: relative !important;
}

/* Absolute positioning */
.u-absolute {
    position: absolute !important;
}

/* Floats */
.u-floatleft {
    float: left !important;
}

.u-floatright {
    float: right !important;
}

/* Center */
.u-center {
    margin: 0 auto;
}

/* Sticky */
.u-sticky-top {
    position: sticky;
    z-index: z('sticky');
    margin-bottom: -1px;
    top: 0;
}

.u-sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: z('sticky-bottom');
}


/* Positions */
@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        //repeated class to override the base class without the use of !important
        .u-top#{$i}.u-top#{$i} {
            top: #{$i}px;
        }
    }
}

@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        //repeated class to override the base class without the use of !important
        .u-bottom#{$i}.u-bottom#{$i} {
            bottom: #{$i}px;
        }
    }
}

/* Paddings */
@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        .u-p#{$i} {
            padding: #{$i}px;
        }
    }
}

@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        .u-pt#{$i} {
            padding-top: #{$i}px;
        }
    }
}

@for $i from 0 through 100 {
    @if $i % 2 == 0 {
        .u-pl#{$i} {
            padding-left: #{$i}px;
        }
    }
}

@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        .u-pb#{$i} {
            padding-bottom: #{$i}px;
        }
    }
}

@for $i from 0 through 100 {
    @if $i % 2 == 0 {
        .u-pr#{$i} {
            padding-right: #{$i}px;
        }
    }
}

/* Margins */
@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        .u-m#{$i} {
            margin: #{$i}px !important;
        }
    }
}

@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        .u-mt#{$i} {
            margin-top: #{$i}px !important;
        }
    }
}

@for $i from 0 through 100 {
    @if $i % 2 == 0 {
        .u-ml#{$i} {
            margin-left: #{$i}px !important;
        }
    }
}

@for $i from 0 through 200 {
    @if $i % 2 == 0 {
        .u-mb#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }
}

@for $i from 0 through 100 {
    @if $i % 2 == 0 {
        .u-mr#{$i} {
            margin-right: #{$i}px !important;
        }
    }
}

.u-mt55 {
    margin-top: 55px !important;
}

.u-mt65 {
    margin-top: 65px !important;
}

.u-mt-1 {
    margin-top: -1px !important;
}

// /* Text */

.u-text-uppercase {
    text-transform: uppercase !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-left {
    text-align: left !important;
}

.u-text-center {
    text-align: center !important;
}

.u-underline {
    text-decoration: underline !important;
}

.u-no-underline {
    text-decoration: none !important;
}

.u-fw100 {
    font-weight: 100 !important;
}

.u-fw300 {
    font-weight: 300 !important;
}

.u-fw400 {
    font-weight: 400 !important;
}

.u-fw500 {
    font-weight: 500 !important;
}

.u-fw600 {
    font-weight: 600 !important;
}

.u-fw700 {
    font-weight: 700 !important;
}

.u-italic {
    font-style: italic;
}

// /* Colors */
.u-red,
.u-error,
.u-error-red {
    color: $color-red !important;
}

.u-green,
.u-success,
.u-success-green {
    color: $color-green !important;
}

.u-orange,
.u-warning,
.u-warning-orange {
    color: $color-orange !important;
}

.u-warning-orange--stroke {
    stroke: $color-orange !important;
}

.u-yellow {
    color: $color-yellow !important;
}

.u-white,
.u-color-white {
    color: $color-white !important;
}

.u-black,
.u-color-primary-black {
    color: $color-primary-black !important;
}

.u-color-secondary-black {
    color: $color-secondary-black;
}

.u-color-tertiary-black,
.u-label-color {
    color: $color-tertiary-black;
}

// /* Fonts  */

.u-font-size {
    &--12 {
        @include fz(1.2);
    }

    &--14 {
        @include fz(1.4);
    }

    &--16 {
        @include fz(1.6);
    }

    &--24 {
        @include fz(2.4);
    }

    &--52 {
        @include fz(5.2);
    }
}

.full-width,
.u-full-width {
    width: 100%;
}

.u-half-width {
    width: 50%;
    max-width: 400px;
}

.u-full-height {
    height: 100%;
}

// /* Text for screen readers only  */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// /* Add ellipsis to overflow text  */

.u-ellipsis {
    @include ellipsis;
}

.selectable {
    cursor: pointer;
}

.show-if-print {
    display: none !important;
}

// /* displays */
.u-inline {
    display: inline;
}

.u-inline__block {
    display: inline-block;
}

.u-block {
    display: block;
}

.u-flex {
    display: flex;
}

/** White Space **/
.white-space-pre-wrap {
    white-space: pre-wrap;
}

/** Scroll **/
.u-overflow-scroll {
    overflow: scroll;
}

.u-disabled-link {
    color: $color-tertiary-black !important;
    cursor: not-allowed;
    pointer-events: none;
}
